jQuery( document ).ready(function($) {

    /**
     * Search form
     */
    if( $( 'body.geodir-page-search .geodir-listing-search' ).length > 0 ) {
        var $form = $( 'body.geodir-page-search .geodir-listing-search' );

        setTimeout(
            function() {
                $form.find( '.gd-search-field-search button' ).trigger( 'click' );
            }, 
            800
        );

        $form.on( "change", '#geodir_search_post_country' ,function() {
            var country_slug = $( this ).val();


            $form.find( '.gd-search-field-near input' ).val( '' );
            $form.find( '.geodir-location-search-type' ).val( '' );
            $form.find( '.geodir-location-search-type' ).attr( 'name', '' );
            $form.find( '.gd-search-field-search button' ).prop('disabled', true);
            $form.find( '#geodir_search_post_region' ).val( '' );
            $form.find( '#geodir_search_post_city' ).val( '' );
            $form.find( '.gd-search-field-city' ).hide();

            procureco_on_country_update( country_slug );
        });

        $form.on( "change", '#geodir_search_post_region' ,function() {
            var country_slug = $form.find( "#geodir_search_post_country" ).val();
            var region_slug = $( this ).val();

            $form.find( '.gd-search-field-near input' ).val( '' );
            $form.find( '.geodir-location-search-type' ).val( '' );
            $form.find( '.geodir-location-search-type' ).attr( 'name', '' );
            $form.find( '.gd-search-field-search button' ).prop('disabled', true);
            $form.find( '#geodir_search_post_city' ).val( '' );

            procureco_on_region_update( country_slug, region_slug );
        });

        $form.on( "change", '#geodir_search_post_city' ,function() {

            $form.find( '.gd-search-field-near input' ).val( '' );
            $form.find( '.geodir-location-search-type' ).val( '' );
            $form.find( '.geodir-location-search-type' ).attr( 'name', '' );
        });
    }
});


function procureco_on_country_update( country_slug ) {
    
    var $form = jQuery( 'body.geodir-page-search .geodir-listing-search' );

    nonce = $form.find( ".procureco-locations-nonce" ).val();

    jQuery.ajax({
        type : "post",
        dataType : "json",
        url : procureco.ajaxurl,
        data : {
            action: "procureco_regions", 
            country_slug : country_slug, 
            nonce: nonce
        },
        success: function(response) {

            $form.find( ".gd-search-field-region" ).replaceWith( response );
            $form.find( '.gd-search-field-search button' ).prop('disabled', false);
        }
    });
}

function procureco_on_region_update( country_slug, region_slug ) {
    
    var $form = jQuery( 'body.geodir-page-search .geodir-listing-search' );

    nonce = $form.find( ".procureco-locations-nonce" ).val();

    jQuery.ajax({
        type : "post",
        dataType : "json",
        url : procureco.ajaxurl,
        data : {
            action: "procureco_citys", 
            country_slug : country_slug, 
            region_slug : region_slug, 
            nonce: nonce
        },
        success: function(response) {

            $form.find( ".gd-search-field-city" ).replaceWith( response );
            $form.find( '.gd-search-field-search button' ).prop('disabled', false);
        }
    });
}